<template>
    <div>
        <div class="row">
            <AppInput label="Name"
                      :value="user.name"
                      @input="updateProp({ name: $event })"
                      :errors="errors"
                      field="name"
                      placeholder="Name"
                      class="col-12 mb-3"/>
        </div>
        <div class="row">
            <AppInput label="Email"
                      :value="user.email"
                      @input="updateProp({ email: $event })"
                      :errors="errors"
                      field="email"
                      placeholder="Email"
                      class="col-12 mb-3"/>
        </div>
        <div class="row">
            <AppInput label="Password"
                      :value="user.password"
                      @input="updateProp({ password: $event })"
                      :errors="errors"
                      field="password"
                      type="password"
                      placeholder="Password"
                      class="col-12 mb-3"/>
        </div>
        <div class="row">
            <div class="col-12 mb-3">
                <button class="btn btn-primary" :disabled="loading" @click="updateUser">Save</button>
            </div>
        </div>
        <hr>
        <button class="btn btn-outline-secondary mb-3" @click="logout">Log out</button>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AppInput from '@/components/form/AppInput';
import Errors from '@/utils/errors';
import NotificationService from '@/services/notification.service';

export default {
  name: 'UserForm',
  components: { AppInput },
  data() {
    return {
      loading: false,
      errors: new Errors(),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  methods: {
    updateProp(props) {
      this.$store.commit('user/userProp', props);
    },
    updateUser() {
      this.loading = true;

      this.$store.dispatch('user/updateProps')
        .then((res) => {
          NotificationService.success(res.message);
        })
        .catch((err) => this.errors.set(err.response.data.errors))
        .finally(() => {
          this.loading = false;
        });
    },
    logout() {
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>
