<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-8">
                <h5 class="ms-3 mb-4 text-secondary">Profile</h5>
            </div>
        </div>
        <div class="row justify-content-center">
            <UserForm class="col-xl-6 col-lg-8"/>
        </div>
    </div>
</template>

<script>
import UserForm from '@/components/user/UserForm';

export default {
  components: { UserForm },
};
</script>
